const appConfig = {
    apiPrefix: 'https://tenderfy-b577478fd264.herokuapp.com/api/',
    authenticatedEntryPath: '/',
    unAuthenticatedEntryPath: '/',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
